import React, { Component } from "react";
import "../css/privacyPolicy.css";

class TermsOfService extends Component {
  render() {
    return (
      <div
        style={{
          marginTop: 150,
          marginLeft: 200,
          marginRight: 200,
          justifyContent: "center",
          marginBottom: 150,
        }}
      >
        <div className="privacy-container">
          <h1>Privacy Policy</h1>
          <p className="last-updated">Last Updated: 15 September 2024</p>
          <p>
            <strong>Asritsolutions LLC</strong> ("we," "us," or "our") is
            committed to protecting your privacy. This Privacy Policy explains
            how we collect, use, disclose, and protect your personal information
            when you interact with our website, services, or communications,
            including SMS marketing.
          </p>

          <h2>1. Information We Collect</h2>

          <h3>a. Personal Data You Provide Directly</h3>
          <p>
            When you interact with us, you may provide personal information such
            as:
          </p>
          <ul>
            <li>
              Contact Information: Name, email address, phone number, postal
              address
            </li>
            <li>
              Account Information: Username, password, or other credentials
            </li>
            <li>
              Transaction Information: Payment details, billing information, and
              shipping addresses
            </li>
            <li>
              SMS Consent Data: Confirmation of SMS opt-in (date, time, IP
              address)
            </li>
            <li>
              Other Identifying Information: Date of birth, identification
              numbers, etc.
            </li>
          </ul>

          <h3>b. Automatically Collected Information</h3>
          <p>
            We may automatically collect information about your device and
            usage:
          </p>
          <ul>
            <li>
              Device Information: IP address, browser type, operating system,
              language preferences
            </li>
            <li>
              Usage Data: Website browsing data, interaction with our emails or
              SMS messages
            </li>
            <li>
              Cookies and Tracking Technologies: We use cookies and similar
              technologies for analytics and personalization.
            </li>
          </ul>

          <h3>c. Information from Third Parties</h3>
          <p>We may receive information from third-party sources, such as:</p>
          <ul>
            <li>
              Advertising Partners: Information on your interactions with ads
            </li>
            <li>
              Service Providers: Data from third-party platforms we use for SMS
              marketing
            </li>
            <li>
              Publicly Available Information: Social media profiles, publicly
              accessible information
            </li>
          </ul>

          <h2>2. How We Use Your Information</h2>

          <h3>a. Providing and Improving Our Services</h3>
          <p>We use your information to:</p>
          <ul>
            <li>Process transactions and manage your account</li>
            <li>Send you SMS marketing communications (with your consent)</li>
            <li>
              Analyze usage data to improve our services and personalize your
              experience
            </li>
          </ul>

          <h3>b. Service Communications</h3>
          <p>
            We may send service communications via SMS, email, or other methods
            if you have opted in. You can opt out at any time by replying{" "}
            <strong>STOP</strong> to SMS messages or using the unsubscribe link
            in emails.
          </p>

          <h3>c. Legal and Regulatory Requirements</h3>
          <p>
            We may use your data to comply with legal obligations, resolve
            disputes, or prevent fraud.
          </p>

          <h3>d. Research and Analytics</h3>
          <p>
            We analyze usage data to improve our services, conduct research, and
            personalize marketing strategies.
          </p>

          <h3>e. Custom Audiences and Targeted Advertising</h3>
          <p>
            We may use custom audiences and target ads on third-party platforms
            such as Facebook and Google, using hashed (non-readable) data.
          </p>

          <h2>3. Data Sharing and Disclosure</h2>

          <h3>a. Sharing of Information with Third-Party Service Providers</h3>
          <p>
            We may share your phone number with third-party service providers
            who assist us in sending SMS messages. These service providers are
            only authorized to use your phone number for this specific purpose
            and are required to handle your information in accordance with
            applicable privacy laws and regulations.
          </p>
          <p>
            We do not sell, rent, or share your phone number with any other
            third parties for marketing or other purposes.
          </p>

          <h3>b. Business Transfers</h3>
          <p>
            In the event of a merger or acquisition, your information may be
            transferred. We will notify you before this occurs.
          </p>

          <h3>c. Legal Compliance</h3>
          <p>
            We may disclose your information to comply with legal obligations,
            protect our rights, or prevent fraud.
          </p>

          <h3>d. With Your Consent</h3>
          <p>We will share your data only with your explicit consent.</p>

          <h2>4. Use of Phone Numbers for SMS Communication</h2>
          <p>
            By opting in, you consent to receive SMS service messages. <br />
            We collect and use your phone number solely for the purpose of
            sending SMS messages related to following:- <br />
            1. Sms is used to send service notifications. <br />
            2. Sms is used to send account information to customer. <br />
            3. Sms is used to send one time password to user. <br />
            4. Sms is used to chat with customer to resolve there issues.
            <br /> By providing your phone number, you consent to receiving
            these messages from us
            <br /> You can opt out at any time by replying{" "}
            <strong>STOP.</strong>
            <br />
            Data rates may apply.
          </p>

          <h2>5. Data Security</h2>
          <p>
            We use encryption, secure servers, and other measures to protect
            your personal data. However, no method of transmission over the
            internet is 100% secure.
          </p>

          <h2>6. Your Rights</h2>

          <h3>a. Access</h3>
          <p>You have the right to request access to your personal data.</p>

          <h3>b. Correction</h3>
          <p>You can request corrections to any inaccurate information.</p>

          <h3>c. Deletion</h3>
          <p>
            You can request the deletion of your personal data, subject to legal
            exceptions.
          </p>

          <h3>d. Opt-Out</h3>

          <p>
            You can opt out of receiving Email or SMS messages at any time by
            replying with the word “STOP” to any message you receive from us.
            Once you opt out, you will no longer receive SMS communications
            unless you re-subscribe.
          </p>

          <h3>e. Data Portability</h3>
          <p>
            You can request a copy of your data in a machine-readable format.
          </p>

          <h2>7. International Data Transfers</h2>
          <p>
            We may transfer your data to other countries, ensuring appropriate
            safeguards are in place.
          </p>

          <h2>8. Cookies and Tracking Technologies</h2>
          <p>
            We use cookies to personalize your experience. You can control
            cookies through your browser settings.
          </p>

          <h2>9. Children's Privacy</h2>
          <p>
            We do not knowingly collect data from children under 16 without
            parental consent.
          </p>

          <h2>10. Changes to This Privacy Policy</h2>
          <p>
            We may update this policy from time to time. The latest version will
            be posted on this page.
          </p>

          <h2>11. Contact Us</h2>
          <p>If you have any questions about this policy, please contact us:</p>
          <ul>
            <li>Email: support@asritsolutions.com</li>
            <li>
              Mailing Address: 4900 Hopyard Rd Ste 100, Pleasanton, CA 94588
            </li>
            <li>Phone: +1 (408) 520 - 0014</li>
          </ul>
        </div>
      </div>
    );
  }
}
export default TermsOfService;
